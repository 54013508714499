import { animate, motion, useMotionValue, useTransform } from "framer-motion"
import React, { useEffect } from "react"
import PropTypes from "prop-types"

export const AnimatedNumber = ({ from, to, inView, settings }) => {
  const formatter = new Intl.NumberFormat(); 
  const count = useMotionValue(from)
  const rounded = useTransform(count, latest =>
    formatter.format(Math.round(latest))
  )

  useEffect(() => {
    if (inView) {
      animate(count, to, { ...settings })
    }
  }, [inView])

  return (
    <motion.span style={{ transform3D: "translateZ(0)" }}>
      {rounded}
    </motion.span>
  )
}

AnimatedNumber.propTypes = {
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
  settings: PropTypes.object,
  inView: PropTypes.bool.isRequired,
}

export const AnimatedNumberSymbol = ({ inView, delay, symbol }) => (
  <motion.span
    style={{ display: "inline-flex", transform3D: "translateZ(0)" }}
    initial={{ opacity: 0, width: 0 }}
    animate={
      inView && {
        opacity: 1,
        width: "auto",
        transition: { type: "easeIn", duration: 0.6, delay: delay },
      }
    }
  >
    {symbol}
  </motion.span>
)

AnimatedNumberSymbol.propTypes = {
  inView: PropTypes.bool,
  delay: PropTypes.number.isRequired,
  symbol: PropTypes.string.isRequired,
}
