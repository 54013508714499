import React, { useRef } from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import Container from "@components/layout/container"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Grid from "@components/elements/grid"
import Div from "@components/elements/div"
import GradientText from "@components/common/gradient-text"
import Flex from "@components/elements/flex"
import styled, { useTheme } from "styled-components"
import { processImages } from "@utils/process-images"
import Image from "@components/image"
import Anchor from "@components/elements/anchor"
import { HighGradient, LowGradient } from "@components/common/gradient"
import { LogoCarousel } from "@components/common/logo-carousel"
import { CONTAINER_SPACING } from "@utils/standard-spacing"
import { OurNumbers } from "@components/pages/about/our-numbers"
import { useInViewResponsive } from "@hooks/use-in-view-responsive"

const IconWrapper = styled.div`
  width: 5rem;
  height: 5rem;
  padding: 1rem;
  background: white;
  box-shadow: ${props => props.theme.shadow.glassy};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  margin: 0 auto 1rem;
`

const InvestorCard = styled.a`
  display: flex;
  flex-flow: column;
  grid-column: span 1;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 0px 1px rgb(0 0 0 / 20%), 0 8px 16px rgb(0 0 0 / 10%);
  padding: 1rem;
  border-radius: 0.5rem;

  .klipfolio-image-wrapper {
    max-width: 120px;
  }

  p,
  span {
    color: #21264f;
  }

  p {
    font-size: 0.8rem;
  }

  span {
    font-size: 1rem;
    font-weight: 700;
  }
`

const AboutPage = ({ data }) => {
  const { color, shadow } = useTheme()
  const images = processImages(data.images.edges)
  const icons = processImages(data.icons.edges)
  const investors = data.investors.edges

  const numbersRef = useRef(null)
  const numbersInView = useInViewResponsive(numbersRef, 0.2, 0.6)

  return (
    <Layout
      fullWidth
      title={`About Us`}
      description={`Klipfolio was founded back in 2001 to tackle the challenges of real-time data. Since then, we've evolved and changed quite a bit.`}
      marginless
    >
      <Container relative fullWidth noPadding hideOverflow>
        <HighGradient
          src={images["b143ae2d-fab4-4821-b1a9-c3766fcf9317"].cdn}
          loading="eager"
        />
        <Image
          eager
          file={data.hero}
          style={{ zIndex: -1, minHeight: "130px" }}
        />
        <Div
          textContainer
          margin="-7rem auto 0"
          marginMd="-2rem auto -2rem"
          marginSm="-1rem auto 0"
          position="relative"
        >
          <Heading center fontSize="5rem" mobileScale={0.6} margin="0 0 1.5rem">
            <GradientText gradient="linear-gradient(180deg, #6E79E2 -25%, #D03D84 125%)">
              Help people
              <br />
              succeed with data
            </GradientText>
          </Heading>
          <Paragraph
            fontWeight={600}
            center
            fontSize="1.3rem"
            lineHeight="160%"
          >
            At Klipfolio, we believe everyone can make successful data-driven
            decisions. They just need the right tools. Our mission is to break
            down the barriers in data analytics and make it accessible to all,
            regardless of technical background or role.
          </Paragraph>
        </Div>

        <Div
          overflow="hidden"
          position="relative"
          padding="8rem 0"
          paddingSm="4rem 0"
          background={color.indigo700}
          {...CONTAINER_SPACING}
        >
          <Flex
            position="relative"
            zIndex={1}
            textContainer
            margin="0 auto 4rem"
          >
            <Paragraph center color="white">
              Our Values
            </Paragraph>
            <Heading
              margin="0 0 2rem"
              fontSize="3rem"
              center
              as="h2"
              color="white"
            >
              People&nbsp;
              <GradientText gradient="linear-gradient(90deg, #7B6CCC 0%, #BC6894 100%)">
                first
              </GradientText>
            </Heading>

            <Paragraph center color="white">
              Relationships matter. We care deeply about the well-being and
              success of our employees, customers, and partners. We&apos;re
              a&nbsp;
              <Anchor
                link="/about/careers"
                color={color.indigo300}
                hover={color.indigo400}
              >
                diverse, open-minded, international team
              </Anchor>
              &nbsp;pushing big ideas to help your organization make better,
              data-driven decisions.
            </Paragraph>
          </Flex>
          <Grid
            position="relative"
            zIndex={1}
            gap="2rem"
            container
            columns="repeat(5, 1fr)"
            columnsMd="repeat(3, 1fr)"
            columnsSm="repeat(2, 1fr)"
          >
            <Flex>
              <IconWrapper>
                <Image
                  objectFit="scale-down"
                  file={images["edc80973-26ec-4929-9822-3cb65550e371"]}
                />
              </IconWrapper>
              <Heading margin="0 0 0.5rem" color="white" as="h4" center>
                Mission-driven
              </Heading>
              <Paragraph color="white" fontSize="1rem" lineHeight="130%" center>
                Helping customers succeed with data drives us forward every day.
              </Paragraph>
            </Flex>
            <Div>
              <IconWrapper>
                <Image file={images["9d9a9276-1011-4e35-b9ff-e659b9401e93"]} />
              </IconWrapper>
              <Heading margin="0 0 0.5rem" color="white" as="h4" center>
                Friendly
              </Heading>
              <Paragraph color="white" fontSize="1rem" lineHeight="130%" center>
                We thrive in an atmosphere of mutual respect and kindness.
              </Paragraph>
            </Div>
            <Div>
              <IconWrapper>
                <Image file={images["150e1f4e-6a64-462e-b6f9-6d1b0891c84d"]} />
              </IconWrapper>
              <Heading margin="0 0 0.5rem" as="h4" color="white" center>
                Helpful
              </Heading>
              <Paragraph color="white" fontSize="1rem" lineHeight="130%" center>
                We listen and respond with solid support and innovative
                solutions.
              </Paragraph>
            </Div>
            <Div>
              <IconWrapper>
                <Image file={images["2f335207-c39d-4e0b-92d4-53997aaf9256"]} />
              </IconWrapper>
              <Heading margin="0 0 0.5rem" color="white" as="h4" center>
                Knowledgeable
              </Heading>
              <Paragraph color="white" fontSize="1rem" lineHeight="130%" center>
                We&apos;re really into data and enjoy sharing our knowledge &
                expertise.
              </Paragraph>
            </Div>
            <Div>
              <IconWrapper>
                <Image file={images["485e7a3a-cd3c-49bf-abfe-5c162ada084b"]} />
              </IconWrapper>
              <Heading margin="0 0 0.5rem" color="white" as="h4" center>
                Diverse
              </Heading>
              <Paragraph color="white" fontSize="1rem" lineHeight="130%" center>
                We embrace & celebrate diversity within our team & customers.
              </Paragraph>
            </Div>
          </Grid>
          <LowGradient
            src={images["b143ae2d-fab4-4821-b1a9-c3766fcf9317"].cdn}
            loading="lazy"
          />
        </Div>

        <Div {...CONTAINER_SPACING} textContainer>
          <Paragraph center fontWeight="700">
            Our Passion
          </Paragraph>
          <Flex gap="2rem">
            <Heading fontSize="3rem" as="h2" center>
              <GradientText gradient="linear-gradient(90deg, #7B6CCC 0%, #BC6894 100%)">
                Innovation
              </GradientText>
            </Heading>
            <Paragraph>
              Innovation comes from a deep-seated desire to challenge the norm.
              We have a track-record of market disruption and are passionate
              about transforming traditional Business Intelligence from the
              ordinary to the extraordinary.
            </Paragraph>
            <Paragraph>
              Launched in 2012, Klipfolio{" "}
              <Anchor link="https://klipfolio.com/klips">Klips</Anchor> made
              waves in the industry as the first real-time BI reporting solution
              for SMBs. In 2018, we were recognized in Deloitte&apos;s ranking
              of Canada&apos;s 50 fastest growing companies.
            </Paragraph>
            <Paragraph>
              In 2020, we officially launched Klipfolio&nbsp;
              <Anchor link="/powermetrics">PowerMetrics</Anchor>, the first
              analytics solution with a metric-centric architecture. We remain
              ahead of the curve in this evolving market. Read about our&nbsp;
              <Anchor link="/blog/metric-centric-product-vision-bi-ahead-of-its-time">
                product vision
              </Anchor>
              .
            </Paragraph>
            <Paragraph>
              Collaboration is key to learning and growing. We love connecting
              with other thought leaders. If you share our passion, explore our
              resources or&nbsp;
              <Anchor link="/about/contact-us">get in touch</Anchor>.
            </Paragraph>
            <Paragraph fontStyle="italic">
              &quot;Decisions are the lifeblood of business, shaping success and
              failure. At Klipfolio, our mission is to help everyone in an
              organization succeed with data by building a future where
              decisions are made with confidence, understood by all, and
              evidenced by deep knowledge.&quot;
            </Paragraph>
            <Flex flexFlow="row" gap="1rem">
              <Image
                height={70}
                width={70}
                file={images["3d7d9b7b-f24a-4526-a9cf-55d849c80d6a"]}
              />
              <Div>
                <Paragraph fontWeight={700}>Allan Wille,</Paragraph>
                <Paragraph lineHeight="140%">
                  CEO and Co-Founder of Klipfolio
                </Paragraph>
              </Div>
            </Flex>
          </Flex>
        </Div>

        <Div {...CONTAINER_SPACING} hideOnMd>
          <Heading center as="h2" fontSize="3rem" margin="0 0 2rem">
            Learn from our
            <br />
            <GradientText gradient="linear-gradient(90deg, #7B6CCC 0%, #BC6894 100%)">
              thought leaders
            </GradientText>
          </Heading>

          <Grid center gap="4rem" textContainer columns="repeat(2, 1fr)">
            <Flex gap="1rem">
              <Div>
                <Image
                  height={50}
                  width={50}
                  style={{
                    borderRadius: "100%",
                    margin: "0 auto 1rem",
                    boxShadow: shadow.soft,
                  }}
                  file={icons["07a99c63-10ab-4a46-b377-6fdb54bc1ef7"]}
                />
                <Anchor arrow link="/blog" fontSize="1.3rem" lineHeight="140%">
                  Klipfolio Blog
                </Anchor>
              </Div>
              <Paragraph Paragraph fontSize="1.1rem" lineHeight="140%">
                Read blog posts on the topics that matter to you and your
                business.
              </Paragraph>
            </Flex>
            <Flex gap="1rem">
              <Div>
                <Image
                  height={50}
                  width={50}
                  style={{
                    borderRadius: "100%",
                    margin: "0 auto 1rem",
                    boxShadow: shadow.soft,
                  }}
                  file={icons["b9c999df-d4dd-47d8-b0f1-63a0f7391b53"]}
                />
                <Anchor
                  arrow
                  fontSize="1.3rem"
                  lineHeight="140%"
                  link="https://www.metrichq.org/"
                >
                  MetricHQ
                </Anchor>
              </Div>
              <Paragraph fontSize="1.1rem" lineHeight="140%">
                Find industry-standard metric definitions and choose from
                hundreds of pre-built metrics.
              </Paragraph>
            </Flex>
            <Flex gap="1rem">
              <Div>
                <Image
                  height={50}
                  width={50}
                  style={{
                    borderRadius: "100%",
                    margin: "0 auto 1rem",
                    boxShadow: shadow.soft,
                  }}
                  file={icons["3ce41c96-ab4e-44f7-8d94-e5f2dbb95390"]}
                />
                <Anchor
                  arrow
                  fontSize="1.3rem"
                  lineHeight="140%"
                  link="https://metricstack.substack.com/"
                >
                  Metric Stack Newsletter
                </Anchor>
              </Div>
              <Paragraph fontSize="1.1rem" lineHeight="140%">
                Learn from today&apos;s thought leaders about metric-centric
                analytics in the modern data stack.
              </Paragraph>
            </Flex>
            <Flex gap="1rem">
              <Div>
                <Image
                  height={50}
                  width={50}
                  style={{
                    borderRadius: "100%",
                    margin: "0 auto 1rem",
                    boxShadow: shadow.soft,
                  }}
                  file={icons["09a6a2d6-aaca-4331-acb7-48fe7669e820"]}
                />
                <Anchor
                  arrow
                  fontSize="1.3rem"
                  lineHeight="140%"
                  link="/metric-stack"
                >
                  Metric Stack Podcast
                </Anchor>
              </Div>
              <Paragraph fontSize="1.1rem" lineHeight="140%">
                Listen to founders and other business leaders as they share how
                they succeed with data.
              </Paragraph>
            </Flex>
          </Grid>
        </Div>

        <OurNumbers ref={numbersRef} inView={numbersInView} />

        <Flex gap="8rem" gapSm="4rem" {...CONTAINER_SPACING} textContainer>
          <Flex center gap="2rem" gapSm="1rem">
            <Heading center as="h2" fontSize="3rem">
              Don&apos;t just&nbsp;
              <GradientText gradient="linear-gradient(90deg, #7B6CCC 0%, #BC6894 100%)">
                take our word for it
              </GradientText>
            </Heading>
            <Paragraph lineHeight="140%" center margin="0 0 -4rem">
              At Klipfolio, we are inspired every day by our amazing customers.
              Thank you to all the data heroes out there for sharing your
              success with us.
            </Paragraph>
            <div style={{ filter: "brightness(2)" }}>
              <LogoCarousel dark />
            </div>
            <Image file={images["6a591b3b-ba1e-49df-aea6-fbfd87e562d1"]} />
          </Flex>
          <Flex center gap="2rem" gapSm="1rem">
            <Heading as="h2" fontSize="3rem" margin="0 0 2rem">
              Our Investors
            </Heading>
            <Grid gap="1rem" columns="repeat(auto-fill, minmax(180px, 1fr))">
              {investors.map(({ node: investor }, i) => {
                return (
                  <InvestorCard key={`investor-${i}`} href={investor.url}>
                    <Image file={investor.investor} objectFit="scale-down" />
                  </InvestorCard>
                )
              })}
            </Grid>
          </Flex>
        </Flex>
      </Container>
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageQuery {
    investors: allInvestors(filter: { status: { eq: "published" } }) {
      edges {
        node {
          url
          investor {
            cdn
            placeholder
            id
            title
          }
        }
      }
    }
    hero: directusFiles(
      directus_id: { eq: "12f51f24-fdab-4131-bd5d-0e6f0815ad5c" }
    ) {
      cdn(width: 2100, quality: 100)
      directus_id
      id
      placeholder
      title
    }
    icons: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "b9c999df-d4dd-47d8-b0f1-63a0f7391b53"
            "07a99c63-10ab-4a46-b377-6fdb54bc1ef7"
            "09a6a2d6-aaca-4331-acb7-48fe7669e820"
            "3ce41c96-ab4e-44f7-8d94-e5f2dbb95390"
          ]
        }
      }
    ) {
      edges {
        node {
          cdn
          title
          placeholder
          directus_id
        }
      }
    }
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "e790791c-30f3-4a60-be5a-6688e7d4a8f2"
            "2f335207-c39d-4e0b-92d4-53997aaf9256"
            "485e7a3a-cd3c-49bf-abfe-5c162ada084b"
            "edc80973-26ec-4929-9822-3cb65550e371"
            "150e1f4e-6a64-462e-b6f9-6d1b0891c84d"
            "9d9a9276-1011-4e35-b9ff-e659b9401e93"
            "b143ae2d-fab4-4821-b1a9-c3766fcf9317"
            "6a591b3b-ba1e-49df-aea6-fbfd87e562d1"
            "d81cdb7c-ad6a-4e5f-ac57-9da1ebdd14c0"
            "ea14cb64-be70-480f-92d1-6664cceb7234"
            "294fdc12-f8ab-435e-b99e-501ee68a6ccb"
            "582522df-27cf-4067-ab1c-a4970e73bddc"
            "3d7d9b7b-f24a-4526-a9cf-55d849c80d6a"
          ]
        }
      }
    ) {
      edges {
        node {
          cdn
          title
          placeholder
          directus_id
        }
      }
    }
    users: allOurUsers {
      edges {
        node {
          company {
            title
            cdn
            placeholder
            directus_id
          }
        }
      }
    }
  }
`
