import { useInView } from "framer-motion"
import { useState, useEffect } from "react"

export const useInViewResponsive = (
  ref,
  mobileThreshold = 0.2,
  desktopThreshold = 0.6
) => {
  const mobileBreakpoint = 768
  const [viewportWidth, setViewportWidth] = useState(mobileBreakpoint + 1)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => setViewportWidth(window.innerWidth)
      window.addEventListener("resize", handleResize)
      handleResize() // set initial width
      return () => window.removeEventListener("resize", handleResize)
    }
  }, [])

  const isInView = useInView(ref, {
    once: true,
    amount:
      viewportWidth <= mobileBreakpoint ? mobileThreshold : desktopThreshold,
  })

  return isInView
}
