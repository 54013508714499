import {
  AnimatedNumber,
  AnimatedNumberSymbol,
} from "@components/animated/animated-number"
import { IndigoCard } from "@components/card/indigo"
import { HighGradient } from "@components/common/gradient"
import Div from "@components/elements/div"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import { useGradient } from "@hooks/use-gradient"
import { CONTAINER_SPACING } from "@utils/standard-spacing"
import { motion } from "framer-motion"
import React, { forwardRef } from "react"
import styled, { useTheme } from "styled-components"
import PropTypes from "prop-types"

const CircleStyle = styled.div`
  position: absolute;
  width: 140%;
  height: 140%;
  background: ${props => props.color};
  aspect-ratio: 1/1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backface-visibility: hidden;
  filter: blur(100px);
  transform: translate3d(-15%, -15%, 0);
  z-index: -1;
`

const BlurCircle = motion(CircleStyle)

const opacity = [0, 0.5, 0]

const breathVariants = {
  initial: {
    opacity: 0,
  },
  breath1: {
    opacity: opacity,
    transition: {
      duration: 5,
      ease: "easeInOut",
    },
  },
  breath2: {
    opacity: opacity,
    transition: {
      duration: 5,
      ease: "easeInOut",
      delay: 1.5,
    },
  },
  breath3: {
    opacity: opacity,
    transition: {
      duration: 5,
      ease: "easeInOut",
      delay: 3,
    },
  },
  breath4: {
    opacity: opacity,
    transition: {
      duration: 5,
      ease: "easeInOut",
      delay: 4.5,
    },
  },
  gradient: {
    opacity: 1,
    transition: {
      duration: 5,
      ease: "easeInOut",
      delay: 8,
    },
  },
}

export const OurNumbers = forwardRef(({ inView }, ref) => {
  const { color } = useTheme()
  const { gradient } = useGradient()

  return (
    <Div
      overflow="hidden"
      position="relative"
      padding="8rem 2rem"
      paddingSm="4rem 1rem"
      {...CONTAINER_SPACING}
      background={color.indigo700}
      ref={ref}
    >
      <HighGradient
        variants={breathVariants}
        as={motion.img}
        initial="initial"
        animate={inView && "gradient"}
        src={gradient.cdn}
      />
      <Flex position="relative" zIndex={2} margin="0 0 3rem" center>
        <Paragraph color="white">You trust us with yours, here are</Paragraph>
        <Heading color="white" as="h2" fontSize="4rem">
          Our numbers
        </Heading>
      </Flex>
      <Grid
        textContainer
        position="relative"
        zIndex={2}
        gap="2rem"
        gapSm="1rem"
        columns="repeat(2, 1fr)"
        columnsSm="1fr"
      >
        <IndigoCard padding="5rem 0" color="white" center position="relative">
          <BlurCircle
            color={"#FFB85C"}
            variants={breathVariants}
            initial="initial"
            animate={inView && "breath1"}
          />
          <Heading
            color="inherit"
            as="h3"
            fontSize="5rem"
            lineHeight="140%"
            style={{ filter: "drop-shadow(0 6px 10px rgba(0, 0, 0, 0.5))" }}
          >
            <AnimatedNumberSymbol
              symbol={"+"}
              inView={inView}
              delay={0.5}
            />
            <AnimatedNumber
              from={0}
              to={2000}
              inView={inView}
              settings={{ duration: 0.9, ease: "easeIn" }}
            />
          </Heading>
          <Paragraph
            color={color.indigo200}
            fontSize="1.4rem"
            style={{ filter: "drop-shadow(0 6px 10px rgba(0, 0, 0, 0.5))" }}
          >
            Customers
          </Paragraph>
        </IndigoCard>
        <IndigoCard padding="5rem 0" color="white" center position="relative">
          <BlurCircle
            color={"#FDA1B9"}
            variants={breathVariants}
            initial="initial"
            animate={inView && "breath2"}
            position="relative"
          />
          <Heading
            color="inherit"
            as="h3"
            fontSize="5rem"
            lineHeight="140%"
            style={{ filter: "drop-shadow(0 6px 10px rgba(0, 0, 0, 0.5))" }}
          >
            <AnimatedNumberSymbol
              symbol={"+"}
              inView={inView}
              delay={2}
            />
            <AnimatedNumber
              from={0}
              to={3500}
              inView={inView}
              settings={{ delay: 1.5, duration: 0.9, ease: "easeIn" }}
            />
          </Heading>
          <Paragraph
            color={color.indigo200}
            style={{ filter: "drop-shadow(0 6px 10px rgba(0, 0, 0, 0.5))" }}
          >
            Users
          </Paragraph>
        </IndigoCard>
        <IndigoCard padding="5rem 0" color="white" center position="relative">
          <BlurCircle
            color={"#707EFA"}
            variants={breathVariants}
            initial="initial"
            animate={inView && "breath3"}
          />
          <Heading
            color="inherit"
            as="h3"
            fontSize="5rem"
            lineHeight="140%"
            style={{ filter: "drop-shadow(0 6px 10px rgba(0, 0, 0, 0.5))" }}
          >
            <AnimatedNumberSymbol
              symbol={"+"}
              inView={inView}
              delay={3.5}
            />
            <AnimatedNumber
              from={0}
              to={82}
              inView={inView}
              settings={{ delay: 3, duration: 0.9, ease: "easeIn" }}
            />
          </Heading>
          <Paragraph
            color={color.indigo200}
            style={{ filter: "drop-shadow(0 6px 10px rgba(0, 0, 0, 0.5))" }}
          >
            Countries
          </Paragraph>
        </IndigoCard>
        <IndigoCard padding="5rem 0" color="white" center position="relative">
          <BlurCircle
            color={"#5DD5F6"}
            variants={breathVariants}
            initial="initial"
            animate={inView && "breath4"}
          />
          <Heading
            color="inherit"
            as="h3"
            fontSize="5rem"
            lineHeight="140%"
            style={{ filter: "drop-shadow(0 6px 10px rgba(0, 0, 0, 0.5))" }}
          >
            <AnimatedNumberSymbol
              symbol={"+"}
              inView={inView}
              delay={5}
            />
            <AnimatedNumber
              from={0}
              to={90}
              inView={inView}
              settings={{ delay: 4.5, duration: 0.9, ease: "easeIn" }}
            />
          </Heading>
          <Paragraph
            color={color.indigo200}
            style={{ filter: "drop-shadow(0 6px 10px rgba(0, 0, 0, 0.5))" }}
          >
            Industries
          </Paragraph>
        </IndigoCard>
      </Grid>
    </Div>
  )
})

OurNumbers.displayName = "Our Numbers"

OurNumbers.propTypes = {
  inView: PropTypes.bool,
}
